import { render, staticRenderFns } from "./SelectTagsForEvent.vue?vue&type=template&id=a55cedf2&scoped=true"
import script from "./SelectTagsForEvent.vue?vue&type=script&lang=js"
export * from "./SelectTagsForEvent.vue?vue&type=script&lang=js"
import style0 from "./SelectTagsForEvent.vue?vue&type=style&index=0&id=a55cedf2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a55cedf2",
  null
  
)

export default component.exports
<template>
    <div class="pop-up" @click.self="redirect" @keydown.esc="redirect">
        <img class="closeButton" src="@/assets/icons/close-button.svg" @click="redirect">
        <div class="tag_heading">
            <h1>Pick a Tag For Event</h1>
        </div>
        <!-- <div class="search-bar"> -->
            <!-- <tag-search :trigger="1" :n="selectedTags.length" :tags="tags" :limit="false" :showSearch="true" @updated="updateFunc"/> -->
        <!-- </div> -->
        <div class="tags">
            <tag-button 
                v-for="(tag, index) in tags" 
                :index="index"
                :top="[]"
                :key="tag.id_tag" 
                :tag="tag" 
                :ref="'tagBtn_' + tag.id" 
                :tags="selectedTags"
                :allTags="tags"
                :includeFocuseForSelection="false"
                @clicked="selectedTag" />
        </div>
        <!-- Button to go back to create event form -->
    </div>
</template>

<script>
import TagButton from './tag/TagButton.vue'
import TagSearch from './tag/TagSearch.vue'
export default {
  name: "FullScreenTags",
  components: { TagSearch, TagButton },
  props: {
    selectedTags :{
        default: [],
        type: Array
    }
  },
  data() {
    return {
        tags: []
    }
  },    
  async mounted() {
      this.getTags();
  },
  methods: {
      async getTags() {
        const response = await this.$api.getTags();
        if(response.success) {
            this.tags = response.tags;
        }
      },
      selectedTag(tagId) {
        let index = this.selectedTags.indexOf(tagId);
        if (index !== -1) this.selectedTags.splice(index, 1);
        else this.selectedTags.push(tagId);
      },
      redirect() {
        const info = {
            closeScreen: false,
        }
        this.$emit("sendSelectedTags", info);
      }
  }
}
</script>

<style lang="scss" scoped>
    .pop-up {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        z-index: 100;
        background-color: var(--dark);
        color: var(--light);


        .closeButton {
        @extend .pointer;
        @extend .noselect;
        @extend .nodrag;
        position: absolute;
        top: 10px;
        left: 10px;

        &:hover {
        @include popup();
        }
    }
    }

    // .search-bar {
    //     margin: 100px auto;
    //     margin-bottom: 20px;
    //     width: 80%;
    //     border-bottom: 2px solid var(--light);

    // }

    .tag_heading {
        margin: 100px auto;
        margin-bottom: 20px;
        width: 80%;
        border-bottom: 2px solid var(--light);
        text-align: center;

        & > * {
                margin-bottom: 10px;
        }

    }

    .tags {
        margin: 0 auto;
        width: 100%;
        max-width: 75%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 5px;
    }
</style>
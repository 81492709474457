<template>
    <div class="selectTags">
        <div class="selectTags__text">
            <span style="margin: 5px 0;">Add Tags To Event</span>
            <!-- <search-icon @click.native="toggleFullScreen"/> -->
        </div>
        <div class="selectTags__tagPicker" v-if="!checkScreen">
            <tag-button
                v-for="(tag, index) in tags.slice(0, 9)" 
                :key="tag.id_tag" 
                :tag="tag"
                :top=[] 
                :index="index"
                :ref="'tagBtn_' + tag.id_tag" 
                :tags="selectedTags"
                :allTags="tags"
                :includeFocuseForSelection="false"
                @clicked="selectedTag" />      
      
            <div class="showAllTags" @click="toggleFullScreen">...</div>
        </div>
    </div>
</template>

<script>
import { BIconChevronDown } from "bootstrap-vue";
import TagButton from '../tag/TagButton.vue';
import SearchIcon from '../SearchIcon.vue';

export default {
  components: { TagButton, BIconChevronDown, SearchIcon },
    name: 'SelectTagsForEvent',
    props: {
        checkScreen: Boolean
    },
    data() {
        return {
            tags: [],
            selectedTags: [],
            showFullScreen: false
        }
    },
    async mounted() {
        this.getTags();
    },
    methods: {
        async getTags() {      
            const response = await this.$api.getTags();
            if(response.success) {
                for(let i = 0; i < response.tags.length; i++) {
                    this.tags.push(response.tags[i]);
                }
            }
        },
        selectedTag(tagId) {
            let index = this.selectedTags.indexOf(tagId);
            if (index !== -1) this.selectedTags.splice(index, 1);
            else this.selectedTags.push(tagId);
        },
        toggleFullScreen() {
            this.showFullScreen = true;
            const infoObject = {
                showFullScreen: this.showFullScreen,
                selectedTags: this.selectedTags
            }
            this.$emit("showFullScreen", infoObject)
        }
    },
    watch: {
        selectedTags() {
            this.$emit("updateSelectedTags", this.selectedTags);
        }
    }
}
</script>

<style lang="scss" scoped>
    .selectTags {
        background-color: var(--medium);
        color: var(--light);
        width: 100%;
        max-width: 295px;
        padding: 5px;
        margin: 0 auto;

        &__text {
            border: 1px solid rgb(214, 124, 28);
            border-radius: 15px;
            font-size: auto;
            padding: 5px 5px 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            & > span {
                flex-grow: 4;
            }
        }

        &__tagPicker {
            margin: 10px 0 10px 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            row-gap: 5px;

            & > * {
                background-color: var(--medium-dark);
            }

            & > .showAllTags {
                @extend .pointer;
                display: inline-block;
                padding: 8px 16px;
                border: none;
                border-radius: 15px;
                margin: 1px;
                font-size: 0.8em;
            }
        }
    }

</style>


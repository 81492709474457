<template>
    <div class="searchIcon">
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-search"
        >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
    </div>
</template>

<script>
export default {
    name: "SearchIcon"
}
</script>

<style lang="scss" scoped>
    .searchIcon {
        @extend .pointer;
        background-color: var(--medium-dark);
        width: 20px;
        height: 20px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;

        svg {
            height: 1em;
        }
    }
</style>
<template>
  <form class="form shadow" @submit.prevent="setAdminData($event)">
    <p class="AdminName">
      Current Admin Name:
      <span class="AdminName__Value">{{ makeAdminName }}</span>
    </p>
    <input type="text" placeholder="Enter New Admin Name" ref="name" required />
    <input type="submit" value="Change" />
  </form>
</template>

<script>
// Store
import { useUserStore } from "@/stores/UserStore";
import { mapStores } from "pinia";

export default {
  data() {
    return {
      adminName: "",
      adminImg: "",
    }
  },
  methods: {
    setAdminData() {
      let getImg = this.$store.getAdminImg() ? this.$store.getAdminImg() : ""
      let inpVal = this.$refs.name.value
      this.$api.setAdminData({
        title: inpVal,
        img: getImg,
      }, this.userStore.accessToken)
      this.adminName = inpVal
      this.$store.setAdminPostName(this.adminName)
      this.$refs.name.value = ""
    },
  },
  computed: {
    ...mapStores(useUserStore),
    makeAdminName() {
      if (this.adminName !== "" && this.adminName !== undefined) {
        return this.adminName
      } else {
        return "Post Admin"
      }
    },
  },
  async mounted() {
    let res = await this.$api.getAdminData()
    if (res.success === true) {
      this.adminName = res.data.title
      this.adminImg = res.data.img
      this.$store.setAdminPostName(this.adminName)
      this.$store.setAdminImage(this.adminImg)
    } else {
      let adminPostTitle = this.$store.getAdminPostName()
      let adminPostImg = this.$store.getAdminImg()
      if (adminPostTitle.length > 0) {
        this.adminName = adminPostTitle
        this.$store.setAdminPostName(adminPostTitle)
      } else {
        this.adminName = "Post Admin"
        this.$store.setAdminPostName("Post Admin")
      }
      if (adminPostImg.length > 0) {
        this.adminImg = adminPostImg
        this.$store.setAdminImage(adminPostImg)
      } else {
        this.adminImg = ""
        this.$store.setAdminImage("")
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.form {
  @include center;
  padding: 5%;
  margin-bottom: 15px;

  @include box-shadow;
  background-color: var(--background-dark);
  text-align: center;
  border: none;
  border-radius: 15px;
  input {
    display: block;
    box-sizing: content-box;
    width: 90%;
    max-width: 90%;
    @include center;
    padding: 15px;

    border: none;
    border-radius: 15px;
    background-color: #f1f1fe;
    color: #090909;

    @include placeholder {
      color: #8b8a92;
      font-size: 1.1em;
    }

    &[readonly] {
      background: var(--text-dark);
    }
    /*animation: fadeIn .3s, popup .4s;*/
  }
  .AdminName {
    color: white;
    text-align: left;
    margin-left: 10px;

    &__Value {
      font-weight: bold;
      font-size: 1.2rem;
      color: var(--orange);
    }
  }
  input[type="submit"] {
    @extend .pointer;
    @extend .noselect;
    @include gradient;

    width: 30%;
    min-width: 6em;
    margin-top: 15px;
    padding: 15px;

    color: #ffffff;
    font-size: 1em;

    &:hover {
      @include popup(1.04);
    }
  }
}
</style>

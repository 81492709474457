<template>
    <div class="container">
        <div class="popup" v-if="popup.show">
            <h1>Successful</h1>
            <h3>{{ popup.message }}</h3>
        </div>
        <form v-on:submit.prevent="onGenerateLink">
            <div class="input-box-container">
                <div class="input-box" v-on:click="focusInput">
                    <span class="prefix">{{ prefix }}</span>
                    <input type="text" ref="input" v-model="queryStrValue" placeholder="e.g. investors name" required />
                </div>
                <div class="input-box-small">
                    <input type="text" v-model="prefix" readonly />
                    <input type="text" ref="input" v-model="queryStrValue" placeholder="e.g. investors name" required />
                </div>
                <input type="submit" value="Create a sharable link" />
            </div>
            <div v-if="generatedLink.length > 0" class="link-preview-container">
                <input type="text" readonly v-model="generatedLink">
                <!-- Turn into a button -->
                <button type="button" class="copy" v-on:click="copyToClipboard">
                    <b-icon-clipboard class="icon"></b-icon-clipboard>
                    <!-- Copy -->
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import * as options from "@/options.json"
import { BIconClipboard } from "bootstrap-vue"

export default {
  name: "ShareLinkCreator",
  components: {
    BIconClipboard
  },
  data() {
    return {
        prefix: "",
        query: "fan",
        queryStrValue: "",
        generatedLink: "",
        timeoutId: null,
        popup: {
            show: false,
            message: "Link copied to clipboard."
        }
    }
  },
  mounted() {
    this.prefix = options.developmentMode === true
        ? `${options.development.client}?${this.query}=`
        : `${options.production.client}?${this.query}=`
  },
  destroyed() {
    this.popup.show = false;
    clearTimeout(this.timeoutId);
  },
  methods: {
    focusInput() {
        this.$refs.input.focus()
    },
    onGenerateLink() {
        if ( this.queryStrValue.trim().length > 0 ) {
            const encodedQuery = btoa(this.queryStrValue);
            this.generatedLink = `${this.prefix}${encodedQuery}`;

            this.$refs.input.blur();
        }
    },
    copyToClipboard() {
        this.popup.show = true;

        this.timeoutId = setTimeout(() => {
            this.popup.show = false;
        }, 3000);

        navigator.clipboard.writeText(this.generatedLink);
    }  
  }
}

</script>

<style lang="scss" scoped>
    .container {
        width: 100%;
        padding-bottom: 30px;

        .popup {
            @include center;
            @include box-shadow;

            animation: fadeIn 0.7s, popup 0.7s, rotate 0.4s;

            position: fixed;
            right: 1rem;
            bottom: 1rem;


            background-color: var(--orange);
            border-radius: 15px;
            padding: 10px;

            h1 {
                color: #242326;
                font-size: 1.5em;
            }
            h3 {
                color: #242326;
                font-size: 1.2em;
            }
        }

        form {
            @include box-shadow;

            display: flex;
            flex-direction: column;
            gap: 20px;

            padding: 40px;
            border: none;
            border-radius: 15px;
            background-color: var(--background-dark);

            .input-box-container {
                display: flex;
                @include respond-to("small") {
                    flex-direction: column;
                    min-width: 100%;
                }
                gap: 20px;

                .input-box {
                    @include respond-to("small") {
                        display: none;
                    }

                    @extend .noselect;
                    @extend .pointer;

                    flex-basis: 100%;
                    font-size: 1em;

                    display: flex;
                    align-items: center;
                    background-color: var(--medium);
                    border: 1px solid var(--medium);
                    border-radius: 15px;
                    padding-left: 0.5rem;
                    overflow: hidden;

                    .prefix {
                        padding-right: 5px;
                        color: white;
                    }

                    input {
                        flex-grow: 1;
                        background: #fff;
                        border: none;
                        outline: none;
                        padding: 17px;
                        @include placeholder {
                            color: #8b8a92;
                            font-size: 1.1em;
                        }
                    }

                    &:focus-within {
                        outline: solid #fff 1px;
                        outline-offset: 1px;
                        border: 1px solid black;
                    }
                }

                .input-box-small {
                    display: none;
                    @include respond-to("small") {
                        display: flex;
                    }

                    flex-direction: column;
                    gap: 20px;

                    input {
                        flex-grow: 1;

                        border: none;
                        padding: 15px;
                        border-radius: 15px;

                        &[readonly] {
                            background: var(--text-dark);
                        }
                    }
                }

                input[type="submit"] {
                    @extend .pointer;
                    @extend .noselect;
                    @include gradient;

                    max-width: 200px;
                    color: #ffffff;
                    font-size: 1em;
                    padding: 15px;
                    
                    border: none;
                    border-radius: 15px;

                    @include respond-to("small") {
                        @include center;
                    }

                    &:hover {
                        @include popup(1.04);
                    }
                }
            }

            .link-preview-container {
                @extend .noselect;
                @extend .pointer;

                flex-basis: 100%;
                font-size: 1em;

                display: flex;
                align-items: center;
                gap: 20px;

                @include respond-to("small") {
                    flex-direction: column;
                }

                input {
                    flex-grow: 1;

                    border: none;
                    padding: 15px;
                    border-radius: 15px;

                    @include respond-to("small") {
                        width: 90%;
                    }

                    &[readonly] {
                       background: var(--text-dark);
                    }
                }

                .copy {
                    @extend .pointer;
                    @extend .noselect;
                    @include gradient;

                    max-width: 200px;
                    color: #ffffff;
                    font-size: 1em;
                    padding: 12px;
                    
                    border: none;
                    border-radius: 15px;

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @include respond-to("small") {
                        @include center;
                    }

                    &:hover {
                        @include popup(1.04);
                    }

                    .icon {
                        // margin-right: 5px;
                        font-size: 20px;

                    }
                }
            }
        }
    }
</style>
<template>
  <div class="container admin-page">
    <nav>
      <router-link tag="button" to="/">Back to feed</router-link>
    </nav>

    <full-screen-tags v-if="showFullTagScreen" :selectedTags="selectedTags" @sendSelectedTags="getInfoFromPopUp"/>

    <div class="controls">
      <div class="control">
        <h1>Create an admin post</h1>
        <PostForm :admin="true" />
      </div>
      <div class="split">
        <div class="control">
          <h1>Admin Photo</h1>
          <AdminImage />
        </div>
        <div class="control">
          <h1>Admin Name Change</h1>
          <AdminName />
        </div>
      </div>
      <div class="split">
        <div class="control event">
          <h1>Create an admin event</h1>
          <EventForm :admin="true" @screenState="screenState" :screen="showFullTagScreen"/>
        </div>
        <div class="control user">
          <h1>Create a new user</h1>
          <create-user></create-user>
        </div>
      </div>
      <div class="control">
        <h1>Create a link for sharing</h1>
        <ShareLinkCreator />
      </div>
    </div>
  </div>
</template>

<script>
import PostForm from "@/components/post/PostForm"
import EventForm from "@/components/group/EventForm"
import ProfileSetup from "@/components/ProfileSetup"
import CreateUser from "@/components/CreateUser"
import AdminImage from "@/components/AdminImage"
import AdminName from "@/components/AdminName"
import FullScreenTags from '../components/FullScreenTags.vue'
import ShareLinkCreator from '../components/admin/ShareLinkCreator.vue'

export default {
  name: "Admin",
  components: {
    CreateUser,
    ProfileSetup,
    PostForm,
    EventForm,
    AdminImage,
    AdminName,
    FullScreenTags,
    ShareLinkCreator,
  },
  data() {
    return {
      showFullTagScreen : false,
    }
  },
  methods: {
    getInfoFromPopUp(info) {
      this.showFullTagScreen = info.closeScreen
    },
    screenState(info) {
      this.showFullTagScreen = info.showFullScreen;
      this.selectedTags = info.selectedTags;
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-page {
  width: 60%;
  margin: 0 auto;

  @include respond-to("medium") {
    width: 90%;
  }

  button {
    @include button($gradient: true);
  }
  .controls {
    margin-bottom: 20px;
    margin-top: 10px;

    .split {
      display: grid;
      grid-template-areas: "left right";
      grid-template-columns: 0.8fr 1.2fr;
      grid-column-gap: 20px;

      @include respond-to("small") {
        display: block;
      }
    }
    .event {
      grid-area: left;
    }
    .user {
      grid-area: right;
      > .container::v-deep {
        object-fit: cover;
        width: 100%;
        max-height: 100%;
      }
    }
    .control {
      margin-bottom: 30px;
      border-top: 2px solid var(--orange);

      h1 {
        color: var(--text);
        text-align: center;
      }
      h2 {
        margin: 0;
        color: var(--text);
        font-size: 1em;
        text-align: center;
      }
    }
  }
  .container::v-deep {
    form {
      @include respond-to("small") {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .coverImgContainer {
    width: 100%;
    text-align: center;
  }
}
</style>

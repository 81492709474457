<template>
  <div class="expandable" v-bind:class="{ expand: expand }">
    <h3 @click="expand = !expand">Create event</h3>
    <b-icon-chevron-down
      @click="expand = !expand"
      v-bind:class="{ expand: expand }"
    ></b-icon-chevron-down>

    <form @submit.prevent="handleSubmit">
      <h4>{{ errorMsg }}</h4>

      <input
        v-model="model.title"
        type="text"
        placeholder="Event title"
        min="3"
        maxlength="60"
        class="event-input"
      />
      <textarea
        v-model="model.description"
        type="text"
        placeholder="Event description"
        rows="5"
        maxlength="250"
        class="event-input"
      ></textarea>
      <SelectTagsForEvent
        v-if="expand && admin"
        @showFullScreen="showFullScreen"
        :checkScreen="screen"
        @updateSelectedTags="getSelectedTags"
      />
      <b-form-datepicker v-model="model.date_from"></b-form-datepicker>
      <b-form-datepicker v-if="checked" v-model="model.date_to"></b-form-datepicker>
      <b-form-timepicker v-model="model.date_time" locale="de" placeholder="Selected time"></b-form-timepicker>
      <div class="checkbox-container">
        <Switcher label="Insert multiple day event" v-model="checked"></Switcher>
      </div>
      <input type="submit" value="Create event" />
    </form>
    <div>
      {{ handleDateTo }}
    </div>
  </div>
</template>

<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import { BCalendar, BIconChevronDown, BFormTimepicker, BFormDatepicker, BFormCheckbox } from "bootstrap-vue";
import SelectTagsForEvent from "@/components/admin/SelectTagsForEvent.vue";
import Switcher from "@/components/Switcher.vue";

export default {
  name: "GroupAdminMenu",
  components: { BCalendar, BIconChevronDown, BFormTimepicker, BFormDatepicker, BFormCheckbox, SelectTagsForEvent, Switcher },
  props: {
    id: {
      type: Number,
      default: null,
      required: false
    },
    msg: "msg",
    mainTabId: { // Used for extra information when creating event
      type: Number,
      default: null
    },
    admin: {
      type: Boolean,
      default: false,
    },
    screen: Boolean,
  },
  data() {
    return {
      model: {
        title: "",
        description: "",
        tags: [],
        date_from: new Date(),
        date_to: new Date(),
        date_time: "12:00:00",
      },
      checked: false,
      expand: false,
      errorMsg: "",
    };
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      let selectedTags = null;
      if (this.model.tags.length > 0) {
        selectedTags = [];
        for (const tag of this.model.tags) {
          selectedTags.push(tag.toString());
        }
        selectedTags = selectedTags.join(",");
      }

      let dateFrom = new Date(this.model.date_from).getTime();
      let dateTo = new Date(this.model.date_to).getTime();

      if(!this.checked) {
        this.model.date_to = this.model.date_from;
      }

      if (this.model.title.length < 3 || this.model.title.length >= 60) {
        this.errorMsg = "Event title should have minimum of 3 and maximu of 60 characters.";
        return;
      } else if (this.model.description.length >= 250) {
        this.errorMsg = "Event description should have be under 250 characters.";
      } else if (dateFrom > dateTo) {
        this.errorMsg = "Something is wrong with dates.";
      } else {
        // Get groups main tab data
        const response = await this.$api.insertEvent(
          this.model.title,
          selectedTags,
          this.model.description,
          this.model.date_from,
          this.model.date_to,
          this.model.date_time,
          this.id,
          this.userStore.user.id,
          this.admin,
          this.mainTabId,
          this.userStore.accessToken
        );

        if (response.success === true) {
          this.errorMsg = "";

          // Clear form input
          this.model.title = "";
          this.model.tags = [];
          this.model.description = "";
          this.model.date_time = "12:00:00";
          this.model.date_from = new Date();
          this.model.date_to = new Date();
          this.checked = false;
          this.expand = false;
        } else {
          this.errorMsg = response.msg;
        }
      }
    },
    showFullScreen(infoState) {
      this.$emit("screenState", infoState);
    },
    getSelectedTags(sTagsId) {
      this.model.tags = sTagsId;
    },
  },
  computed: {
    ...mapStores(useUserStore),
    handleDateTo() {
      if(this.model.date_from) {
        if(this.checked === false) {
          this.model.date_to = this.model.date_from;
        }
      }
      return '';
    }
  },
  watch: {
    checked(newState) {
      if(newState) {
        this.model.date_to = new Date();
      } else {
        this.model.date_to = new Date(this.model.date_from);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
div {
  @include side-component;
  padding: 10px;
  text-align: center;
  position: relative;

  overflow: hidden;
  &.expandable {
    transition: max-height 0.3s;
    max-height: 62px;
    &.expand {
      overflow: visible !important;
      max-height: 1200px;
    }
  }

  svg {
    position: absolute;
    top: 22px;
    right: 22px;
    transition: transform 0.3s;
    &:not(.expand) {
      transform: rotateZ(90deg);
    }
  }

  h3 {
    @include side-component-title;
    &:hover {
      background: var(--background);
    }
    padding: 15px;
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.2s;
  }
  h4 {
    color: var(--orange);
  }

  form {
    input,
    textarea {
      @include text-input;
      @include center;

      display: block;
      box-sizing: content-box;
      max-width: 280px;
      width: 80%;
      padding: 10px;
      margin-bottom: 10px;
    }

    textarea::placeholder {
      color: lightgrey;
    }

    input[type="submit"] {
      @include button($gradient: true);
      @include center;
      width: 60%;
    }

    .b-form-datepicker::v-deep {
      display: flex;
      padding: 5px;
      margin: 10px auto;

      background-color: var(--medium);
      overflow: visible !important;

      width: 100%;
      max-width: 300px;

      button.btn {
        display: none;
      }

      label.form-control {
        width: 100%;
        padding: 10px;
        box-shadow: 0 0 0 1px var(--orange) inset;
        border-radius: 15px;

        cursor: pointer;

        bdi.sr-only {
          margin-left: 5px;
        }
      }
      [role="dialog"].dropdown-menu {
        transform: translate3d(320px, -105px, 0px) !important;
        background-color: var(--medium);
        border-radius: 15px;
        z-index: 10;

        @include respond-to("small") {
          transform: translate3d(4px, 73px, 0px) !important;
        }

        button.btn {
          display: inline;
        }

        .b-calendar-inner {
          background-color: var(--medium);
          border-radius: 15px;
          overflow: hidden;
          padding: 5px;
        }
        .b-calendar-header {
          display: none;
        }

        .b-calendar-nav {
          display: flex;
          flex-direction: row;
          text-align: center;
          button {
            flex: 1 1 0px;
            line-height: 1em;
            @include button($padding: 5px, $color: var(--dark-light));
          }
        }
        .b-calendar-grid-weekdays {
          display: flex;
          flex-direction: row;
          text-align: center;
          .col {
            flex: 1 1 0px;
          }
        }
        .b-calendar-grid-body {
          .row {
            display: flex;
            flex-direction: row;
            .col {
              flex: 1 1 0px;
              text-align: center;
              padding: 4px;
              margin: 0;

              .btn {
                @include button($padding: 5px, $color: var(--medium-dark));
                width: 100%;
                margin: 0;
                padding: 4px 0;
                display: block;
                &.text-muted {
                  opacity: 0.4;
                }
                &.focus {
                  box-shadow: 0 0 3px var(--orange-light);
                }
                &.active {
                  @include gradient();
                }
              }
            }
          }
        }
      }
    }

    .checkbox-container {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      padding: 0;
    }

    .b-form-timepicker::v-deep {
      display: flex;
      padding: 5px;
      margin: 10px auto;

      background-color: var(--medium);
      overflow: visible !important;

      width: 100%;
      max-width: 300px;
      
      [role="dialog"].dropdown-menu {
        transform: translate3d(320px, -25px, 0px) !important;
        background-color: var(--medium);
        border-radius: 15px;

        @include respond-to("small") {
          transform: translate3d(70px, 50px, 0px) !important;
          z-index: 10;
        }

        div[role="group"] {
          width: auto !important;
          margin-bottom: 0 !important;
          padding: 10px;

          button.btn {
            display: inline;
          }
          .b-time-header {
            display: none;
          }

          .b-time-header + div {
            display: flex;
            box-shadow: 0 0 0 1px var(--orange) inset;
            border-radius: 15px;

            .b-form-spinbutton {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                align-content: center;
              .btn {
                @include button($padding: 5px, $color: var(--medium-dark));
                display: block;
              }
              output {
                display: block;
              }
            }

            & .b-time-hours + div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }

          .b-time-footer {
            display: none;
          }
        }
      }

      button.btn {
        display: none;
      }

      label.form-control {
        width: 100%;
        padding: 10px;
        box-shadow: 0 0 0 1px var(--orange) inset;
        border-radius: 15px;

        cursor: pointer;

        bdi.sr-only {
          margin-left: 5px;
        }
      }
    }


    // .b-time::v-deep {
    //   background-color: var(--medium);
    //   border-radius: 15px;
    //   overflow: hidden;
    //   padding: 5px;
    //   margin-top: 10px;
    //   .b-time-header {
    //     box-shadow: 0 0 0 1px var(--orange) inset;
    //     padding: 10px;
    //     border-radius: 15px;
    //   }
    //   [role="group"] {
    //     display: flex;
    //     flex-direction: row;
    //     text-align: center;
    //     align-items: center;
    //     align-content: center;
    //     .b-form-spinbutton {
    //       /*display: inline-block;*/
    //       display: flex;
    //       flex-direction: column;
    //       align-items: center;
    //       align-content: center;
    //       .btn {
    //         @include button($padding: 5px, $color: var(--medium-dark));
    //         display: block;
    //       }
    //       output {
    //         display: block;
    //       }
    //     }
    //     .d-flex {
    //       display: flex;
    //       flex-direction: column;
    //       align-items: center;
    //       align-content: center;
    //       svg {
    //         flex: 0.8 0.8;
    //       }
    //     }
    //   }
    // }

    // .b-calendar::v-deep {
    //   margin: 0;
    //   padding: 0;
    //   .b-calendar-inner {
    //     background-color: var(--medium);
    //     border-radius: 15px;
    //     overflow: hidden;
    //     padding: 5px;
    //   }
    //   .b-calendar-header {
    //     box-shadow: 0 0 0 1px var(--orange) inset;
    //     padding: 10px;
    //     border-radius: 15px;
    //   }
    //   .b-calendar-nav {
    //     display: flex;
    //     flex-direction: row;
    //     text-align: center;
    //     button {
    //       flex: 1 1 0px;
    //       line-height: 1em;
    //       @include button($padding: 5px, $color: var(--dark-light));
    //     }
    //   }
    //   .b-calendar-grid-weekdays {
    //     display: flex;
    //     flex-direction: row;
    //     text-align: center;
    //     .col {
    //       flex: 1 1 0px;
    //     }
    //   }
    //   .b-calendar-grid-body {
    //     .row {
    //       display: flex;
    //       flex-direction: row;
    //       .col {
    //         /*background: silver;
    //           padding: 3px;*/
    //         flex: 1 1 0px;
    //         text-align: center;
    //         padding: 4px;
    //         margin: 0;
    //         /*outline: 1px solid red;*/

    //         .btn {
    //           @include button($padding: 5px, $color: var(--medium-dark));
    //           width: 100%;
    //           margin: 0;
    //           padding: 4px 0;
    //           display: block;
    //           &.text-muted {
    //             opacity: 0.4;
    //           }
    //           &.focus {
    //             box-shadow: 0 0 3px var(--orange-light);
    //           }
    //           &.active {
    //             @include gradient();
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
</style>

<template>
  <div class="file">
    <div class="popup" v-if="photoSaved">Image was Saved</div>
    <label :for="imageUploadId">
      <img :src="setAdminImg" alt="admin_post_photo" class="photo" />
    </label>
    <div class="info">
      <h4 v-if="photoDeleted">Delete it</h4>
      <h4 v-if="photoSaved">Photo was saved</h4>
      <h4 v-if="photoChosen">Choose a photo</h4>
      <img
        v-if="photoDeleted"
        src="@/assets/icons/trash-2.svg"
        @click="removeImg"
        class="removeButton"
      />
    </div>
    <input
      type="file"
      accept="image/*"
      name="adminImg"
      @change="uploadImage($event)"
      :id="imageUploadId"
    />
    <button @click="saveImg" ref="btn" class="custom-file-upload">
      Save Photo
    </button>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "AdminImage",
  data() {
    return {
      imgData: false,
      imgUrl: false,
      adminImg: "",
      profile_img_url: "",
      adminName: "",
      photoDeleted: false,
      photoChosen: true,
      photoSaved: false,
      imageUploadId: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, ""),
    }
  },
  methods: {
    setImg(imgData) {
      if (this.imgData !== false) {
        this.adminImg = imgData
      }
    },
    async removeImg() {
      let res = await this.$api.getAdminData()
      try {
        document.querySelector("#" + this.imageUploadId).value = ""
      } catch {}
      this.imgData = false
      this.imgUrl = false
      this.photoChosen = true
      this.photoDeleted = false
      this.photoSaved = false
      this.profile_img_url = ""
      this.adminImg = ""
      if (res.success === true) {
        this.adminImg = res.data.img
      }
    },
    async saveImg() {
      this.photoSaved = true
      this.photoChosen = false
      this.photoDeleted = false
      if (this.profile_img_url !== false) {
        let response = await this.$api.uploadImage(this.profile_img_url, this.userStore.accessToken)
        if (response.success === true) {
          this.$store.setAdminImage(response.url)
          this.$api.setAdminData({ title: this.adminName, img: response.url }, this.userStore.accessToken)
        } else {
          this.$store.setAdminPostName("")
          this.$store.setAdminImage("")
        }
      }
    },
    uploadImage(event) {
      this.photoChosen = false
      this.photoSaved = false
      this.photoDeleted = true
      if (event.target.files && event.target.files[0].size < 5 * 1024 * 1024) {
        // File size is limited to 5MB

        // Update imgUrl
        let reader = new FileReader()
        reader.onload = (e) => {
          this.imgUrl = e.target.result
          // This sets a preview of a img
          this.setImg(e.target.result)
        }
        // file content
        reader.readAsDataURL(event.target.files[0])

        this.imgData = new FormData()
        this.imgData.append("file", event.target.files[0])
        this.imgData.append("id", this.userStore.user.id)

        // FormData object - has no url
        this.profile_img_url = this.imgData
      } else {
        alert("Image size must be under 5MB.")
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
    filename() {
      if (this.imgData !== false) {
        const path = document.querySelector("#" + this.imageUploadId).value
        const filename = path.split(/(\\|\/)/g).pop()
        return filename
      } else return ""
    },
    setAdminImg() {
      if (this.adminImg !== "" && this.adminImg !== undefined) {
        return this.adminImg
      } else {
        return require("@/assets/img/default.jpg")
      }
    },
  },
  async mounted() {
    let res = await this.$api.getAdminData()
    if (res.success === true) {
      this.adminName = res.data.title
      this.adminImg = res.data.img
    } else {
      this.adminName = "Post Admin"
    }
  },
}
</script>

<style lang="scss" scoped>
.file {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    @include dot-overflow;
    display: inline-block;
    margin-bottom: 0;

    color: var(--medium);
    font-size: 1em;
    max-width: 200px;
  }

  .popup {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--orange);
    border-radius: 5px;
    padding: 10px;
    color: white;
    opacity: 0;
    transition: opacity 2s;
  }

  .visible {
    opacity: 1;
  }

  .photo {
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 1px solid white;
    border-radius: 50%;
  }

  .custom-file-upload {
    box-sizing: border-box;
    text-align: center;
    border: 1px solid var(--orange);
    color: white;
    margin-top: 10px;
    border-radius: 10px;
    background: var(--orange);
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
    width: 200px;
    font-size: 1em;
  }

  input[type="file"] {
    display: none;
  }

  .text {
    color: white;
  }

  .info {
    h4 {
      @include dot-overflow;
      display: inline-block;
      margin-bottom: 0;
      color: white;

      color: var(--medium);
      font-size: 1em;
      max-width: 200px;
    }
    .removeButton {
      @extend .pointer;
      @extend .nodrag;
      @extend .noselect;
      filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(317deg)
        brightness(103%) contrast(102%);
      margin-left: 5px;
      &:hover {
        @include popup(1.1);
      }
    }
  }
}
</style>

<template>
  <div class="container">
    <div class="alert" v-if="errorMsg.length > 0">
      <h1>{{ msgType }}</h1>
      <h3>{{ errorMsg }}</h3>
    </div>

    <form @submit.prevent="handleSubmit" class="form shadow">
      <input
        type="text"
        placeholder="Username"
        v-model="model.username"
        required
      />
      <span class="generator-outer">
        <input
          type="password"
          placeholder="Password"
          @keydown="showPassword = false"
          v-model="model.password"
        />
        <input
          type="text"
          v-if="showPassword"
          readonly
          placeholder="Generator"
          v-model="model.password"
        />
        <button class="generator" @click="generatePassword" type="button">
          Generate
        </button>
        <svg
          v-if="showPassword"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-clipboard"
          viewBox="0 0 16 16"
        >
          <path
            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
          />
          <path
            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
          />
        </svg>
      </span>
      <input type="email" placeholder="E-mail" v-model="model.email" required />
      <input type="submit" value="Create" />
    </form>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "CreateUser",
  data() {
    return {
      model: {
        username: "",
        password: "",
        email: "",
      },
      showPassword: false,
      registerMode: true,
      errorMsg: "",
      msgType: "Alert",
    }
  },
  computed: { 
    ...mapStores(useUserStore),
  },
  methods: {
    generatePassword() {
      let length = 16,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-!_@",
        retVal = ""
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
      }
      this.model.password = retVal
      this.showPassword = true
    },
    clearForm() {
      this.model.username = ""
      this.model.password = ""
      this.model.email = ""
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.msgType = "Alert"

      if(
        this.validateEmailAndSetErrorMsg() && 
        this.isPasswordValidAndSetErrorMsg() && 
        this.validateUsernameAndSetErrorMsg()) {  

        const response = await this.$api.register(this.model, false)

        if(response.error) {
            this.errorMsg = response.error.message
        } else {
          this.msgType = "Success"
          this.errorMsg = "User successfully created."
          this.clearForm()
        }
      }

    },
    validateUsernameAndSetErrorMsg() {
      const trimedUsername = this.model.username.trim();
      const re = /^[a-zA-Z0-9]+[a-zA-Z0-9-]+[a-zA-Z0-9]+$/;
      if (typeof trimedUsername !== 'string') return false;
      if (trimedUsername.length < 3 || trimedUsername.length > 25) {
        this.errorMsg = "Username length must be between 3 and 25 characters.";
        return false
      };
      if (!trimedUsername.trim().length) {
        this.errorMsg = "Username is a required field.";
        return false
      };
      if (!(re.test(String(trimedUsername).toLowerCase()))) {
        this.errorMsg = "Username can only contain letters, numbers and hyphens('-').";
        return false
      }

      this.errorMsg = "";
      return true;
    },
    isPasswordValidAndSetErrorMsg() {
      const trimedPassword = this.model.password.trim();
      if (typeof trimedPassword !== 'string') return false;
      if (trimedPassword.length < 6 || trimedPassword.length > 50) {
        this.errorMsg = "Password length must be between 6 and 50 characters.";
        return false
      };
      if (!trimedPassword.trim().length) {
        this.errorMsg = "Password is a required field.";
        return false
      };
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/.test(trimedPassword)) {
        this.errorMsg = "Password must contain at least one lowercase and uppercase letter and a number";
        return false
      };
      this.errorMsg = "";
      return true;
    },
    validateEmailAndSetErrorMsg() {
      const trimedEmail = this.model.email.trim();
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (typeof trimedEmail !== 'string') return false;
      if (trimedEmail.length < 8 || trimedEmail.length > 255) {
        this.errorMsg = "E-mail length must be between 8 and 255 characters.";
        return false
      };
      if (!trimedEmail.trim().length) {
        this.errorMsg = "E-mail is a required field.";
        return false
      };

      if (!(re.test(String(trimedEmail).toLowerCase()))) {
        this.errorMsg = "Please enter the correct e-mail address format.";
        return false
      }

      this.errorMsg = "";
      return true;
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  max-width: 500px;
  @include center;

  .form {
    @include center;
    padding: 5%;
    margin-bottom: 15px;

    @include box-shadow;
    background-color: var(--background-dark);
    text-align: center;
    border: none;
    border-radius: 15px;

    h1 {
      @extend .noselect;
      margin-top: 0;
      margin-bottom: 20px;

      color: #f1f1fe;
      font-size: 1.3em;
    }

    // Inputs
    input {
      display: block;
      box-sizing: content-box;
      width: 90%;
      max-width: 90%;
      @include center;
      margin-top: 15px;

      border: none;
      border-radius: 15px;
      background-color: #f1f1fe;
      color: #090909;

      @include placeholder {
        color: #8b8a92;
        font-size: 1.1em;
      }

      &[readonly] {
        background: var(--text-dark);
      }
      /*animation: fadeIn .3s, popup .4s;*/
    }
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="url"] {
      padding: 15px;

      &:focus {
        background-color: #f1f1fe;
      }
    }
    input[type="submit"] {
      @extend .pointer;
      @extend .noselect;
      @include gradient;

      width: 30%;
      min-width: 6em;
      padding: 15px;

      color: #ffffff;
      font-size: 1em;

      &:hover {
        @include popup(1.04);
      }
    }
  }

  .generator-outer {
    position: relative;
    display: inline-block;
    width: 100%;
    svg {
      position: absolute;
      right: 20px;
      bottom: 18px;
      color: var(--text-light);
    }
  }

  // Controls
  a,
  button {
    @extend .pointer;
    @extend .noselect;

    display: block;
    @include center;
    padding: 10px;
    border: none;
    color: #f1f1fe;
    @include button(15px, true);
    border-radius: 15px;

    &:hover {
      color: #f1f1fe;
    }

    &.generator {
      top: 10px;
      right: 0;
      border-radius: 0 15px 15px 0;
      position: absolute;
      word-wrap: unset;

      @include respond-to("medium") {
        margin-right: 0px;
      }

      @include respond-to("small") {
        margin-right: -1px;
      }
    }
  }

  .alert {
    @include center;
    @include box-shadow;

    animation: fadeIn 0.7s, popup 0.7s, rotate 0.4s;

    position: fixed;
    top: 10%;
    right: 10%;

    background-color: var(--orange);
    border-radius: 15px;
    padding: 10px 30px;

    h1 {
      color: #242326;
      font-size: 1.5em;
    }
    h3 {
      color: #242326;
      font-size: 1.2em;
    }
  }
}
</style>
